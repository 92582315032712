import { DateTime } from 'luxon';

export const formatArticlesUrl = (origin?: string | null) => {
  return formatUrl(`/`, origin);
};

export const formatArticleUrl = (slug: string, origin?: string | null) => {
  return formatUrl(`/article/${slug}`, origin);
};

export const formatArticleReferenceBasedUrl = (
  slug: string,
  origin?: string | null,
) => {
  return formatUrl(`/r/article/${slug}`, origin);
};

export const formatArticlePreviewUrl = (
  slug: string,
  origin?: string | null,
) => {
  return formatUrl(`/preview/${slug}`, origin);
};

export const formatTagsUrl = (origin?: string | null) => {
  return formatUrl(`/discover`, origin);
};

export const formatTagUrl = (slug: string, origin?: string | null) => {
  return formatUrl(`/discover/${slug}`, origin);
};

export const formatUrl = (url: string, origin?: string | null) => {
  return `${origin ?? ''}${url}`;
};

export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const formatHeadingDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });

  if (date.hasSame(today, 'day')) {
    return `Today - ${date.toFormat('yyyy-MM-dd')}`;
  } else if (date.hasSame(yesterday, 'day')) {
    return `Yesterday - ${date.toFormat('yyyy-MM-dd')}`;
  } else {
    return date.toFormat('cccc, yyyy-MM-dd');
  }
};

export function getPageTypeAndSlug(pathname: string) {
  const pathSegments = pathname.split('/').filter(Boolean);

  let type = 'main';
  let slug = '';

  if (pathSegments.length > 0) {
    if (pathSegments[0] === 'article') {
      type = 'article';
      slug = pathSegments[1];
    } else if (pathSegments[0] === 'discover' && pathSegments[1]) {
      type = 'discover';
      slug = pathSegments[1];
    }
  }
  return { type, slug };
}

export function getFeedType(pathname: string) {
  const pathSegments = pathname.split('/').filter(Boolean);

  let type = 'discover';

  if (pathSegments.length > 0) {
    if (
      pathSegments[0] === 'auto-demo' ||
      pathSegments[0] === 'auto-demo-search'
    ) {
      type = 'auto-demo';
    } else if (
      pathSegments[0] === 'auto-demo-2' ||
      pathSegments[0] === 'auto-demo-2-search'
    ) {
      type = 'auto-demo-2';
    }
  }
  return type;
}

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const formatAmount = (count: number) => {
  if (Math.floor(count / 1000000) > 0) {
    return `+${Math.floor(count / 1000000)}M`;
  }

  if (Math.floor(count / 1000) > 100) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (Math.floor(count / 1000) > 10) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (Math.floor(count / 1000) > 0) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (count <= 1000) {
    return count;
  }

  return count;
};

export const areArraysEqual = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const isPWAInstalled = () => {
  if (typeof window !== 'undefined') {
    if (window.navigator.standalone) return true;
    if (window.matchMedia('(display-mode: standalone)').matches) return true;
  }
  return false;
};

export const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return (
    /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

export const renderSearchPageSlug = (feedType: string) => {
  switch (feedType) {
    case 'auto-demo':
      return '/auto-demo-search';
    case 'auto-demo-2':
      return '/auto-demo-2-search';
    case 'discover':
      return '/search';
    default:
      return '/search';
  }
};

export const formatHost = (host: string): string => {
  const trimmedHost = host.startsWith('www.') ? host.slice(4) : host;

  return trimmedHost.charAt(0).toUpperCase() + trimmedHost.slice(1);
};

export const handleGAEvent = (type: string, articleId: number) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', `feed_card-${type}`, {
      event_category: 'Feed',
      event_label: `feed_card-${type}`,
      article_id: articleId,
    });
  }
};

export const handleGAPWAEvent = (type: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', `pwa-${type}`, {
      event_category: 'PWA',
      event_label: `pwa-${type}`,
    });
  }
};
